import React from 'react';
import SEO from '../components/layout/Seo';
import Layout from '../components/layout/Layout';
import ClassSchedule from '../components/schedule/Schedule';

const Schedule = () => {
  return (
    <Layout>
      <SEO title="Kurs" />
      <ClassSchedule />
    </Layout>
  );
};

export default Schedule;
